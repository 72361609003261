/* Change Autocomplete styles in Chrome, Safari &  */

@-webkit-keyframes autofill {
    0%, 100% {
        color      : rgba(0, 0, 0, 0.87) !important;
        background : transparent !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    color                       : rgba(0, 0, 0, 0.87) !important;
    transition                  : background-color 5000000s ease-in-out 0s !important;
    background                  : transparent !important;
    -webkit-text-fill-color     : rgba(0, 0, 0, 0.87) !important;
    -webkit-animation-delay     : 1s !important; /* Safari support - any positive time runs instantly */
    -webkit-animation-name      : autofill !important;
    -webkit-animation-fill-mode : both !important;
}

/* Transitions */
.fade-enter-active, .fade-leave-active {
    transition : opacity 0.5s ease;
}

.fade-enter-from, .fade-leave-to {
    opacity : 0;
}

/* Global styles */
body {
    background-color : var(--v-synthesis-base);
}

html {
    overflow-y : auto;
}

.center-vertical-horizontal {
    position  : absolute;
    top       : 50%;
    left      : 50%;
    transform : translate(-50%, -50%);
}

/* Custom Fonts */
/* Aeonik Font */
@font-face {
    font-family    : 'Aeonik Air';
    src            : url('/fonts/aeonik/subset-Aeonik-Air.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-Air.woff') format('woff');
    font-weight    : 100;
    font-style     : normal;
    font-display   : swap;
    letter-spacing : 0.2px;
}

@font-face {
    font-family    : 'Aeonik Air';
    src            : url('/fonts/aeonik/subset-Aeonik-AirItalic.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-AirItalic.woff') format('woff');
    font-weight    : 100;
    font-style     : italic;
    font-display   : swap;
    letter-spacing : 0.2px;
}

@font-face {
    font-family    : 'Aeonik';
    src            : url('/fonts/aeonik/subset-Aeonik-BlackItalic.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-BlackItalic.woff') format('woff');
    font-weight    : 900;
    font-style     : italic;
    font-display   : swap;
    letter-spacing : 0.2px;
}

@font-face {
    font-family    : 'Aeonik';
    src            : url('/fonts/aeonik/subset-Aeonik-Black.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-Black.woff') format('woff');
    font-weight    : 900;
    font-style     : normal;
    font-display   : swap;
    letter-spacing : 0.2px;
}

@font-face {
    font-family    : 'Aeonik';
    src            : url('/fonts/aeonik/subset-Aeonik-Bold.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-Bold.woff') format('woff');
    font-weight    : bold;
    font-style     : normal;
    font-display   : swap;
    letter-spacing : 0.2px;
}

@font-face {
    font-family    : 'Aeonik';
    src            : url('/fonts/aeonik/subset-Aeonik-Light.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-Light.woff') format('woff');
    font-weight    : 300;
    font-style     : normal;
    font-display   : swap;
    letter-spacing : 0.2px;
}

@font-face {
    font-family    : 'Aeonik';
    src            : url('/fonts/aeonik/subset-Aeonik-BoldItalic.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-BoldItalic.woff') format('woff');
    font-weight    : bold;
    font-style     : italic;
    font-display   : swap;
    letter-spacing : 0.2px;
}

@font-face {
    font-family    : 'Aeonik';
    src            : url('/fonts/aeonik/subset-Aeonik-MediumItalic.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-MediumItalic.woff') format('woff');
    font-weight    : 500;
    font-style     : italic;
    font-display   : swap;
    letter-spacing : 0.2px;
}

@font-face {
    font-family    : 'Aeonik';
    src            : url('/fonts/aeonik/subset-Aeonik-Medium.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-Medium.woff') format('woff');
    font-weight    : 500;
    font-style     : normal;
    font-display   : swap;
    letter-spacing : 0.2px;
}

@font-face {
    font-family    : 'Aeonik';
    src            : url('/fonts/aeonik/subset-Aeonik-LightItalic.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-LightItalic.woff') format('woff');
    font-weight    : 300;
    font-style     : italic;
    font-display   : swap;
    letter-spacing : 0.2px;
}

@font-face {
    font-family    : 'Aeonik';
    src            : url('/fonts/aeonik/subset-Aeonik-Regular.woff2') format('woff2'),
    url('/fonts/aeonik/subset-Aeonik-Regular.woff') format('woff');
    font-weight    : normal;
    font-style     : normal;
    font-display   : swap;
    letter-spacing : 0.2px;
}

/* Default Vuetify Overrides */
.v-application, body {
    font-family    : Aeonik, Roboto, sans-serif;
    font-weight    : normal;
    font-style     : normal;
    letter-spacing : 0.2px !important;
}

.fill-width {
    width : 100%;
}

.fit-content-width {
    width : fit-content;
}

.v-btn {
    letter-spacing : 0.2px !important;
    font-size      : 16px !important;
}

/* App styles */
.synthesis-text {
    font-family    : Aeonik, Roboto, sans-serif;
    letter-spacing : 0.2px;
}

.synthesis-inter-text {
    font-family    : Inter, Aeonik, Roboto, sans-serif;
    font-weight    : 600;
    letter-spacing : 0.2px;
}

.text-size-10 {
    font-size      : 10px !important;
    line-height    : 100% !important;
    letter-spacing : 0.5px !important;
}

.synthesis-section-border-radius {
    border-radius : 20px !important;
}

.text-size-14 {
    font-size   : 14px;
    line-height : 130%;
}

.text-size-32 {
    font-size   : 32px;
    line-height : 130%;
}

.synthesis-header-title {
    font-size      : 40px;
    font-weight    : 700;
    line-height    : 100%;
    text-align     : center;
    letter-spacing : -1px;
}

.synthesis-header-title-md {
    font-size      : 64px;
    font-weight    : 700;
    line-height    : 100%;
    text-align     : center;
    letter-spacing : -1px;
}

.synthesis-header-title-lg {
    font-size      : 74px;
    font-weight    : 700;
    line-height    : 100%;
    text-align     : center;
    letter-spacing : -1px;
}

.synthesis-header-subtitle {
    font-size   : 16px;
    font-weight : 500;
    line-height : 120%;
    text-align  : center;
}

.synthesis-header-subtitle-md {
    font-size   : 20px;
    font-weight : 500;
    line-height : 120%;
    text-align  : center;
}

.synthesis-section-title {
    font-size      : 28px;
    font-weight    : 700;
    line-height    : 100%;
    text-align     : center;
    letter-spacing : -0.02em;
}

.synthesis-section-title-md {
    font-size      : 36px;
    font-weight    : 700;
    line-height    : 100%;
    text-align     : center;
    letter-spacing : -0.02em;
}

.synthesis-section-title-lg {
    font-size      : 40px;
    font-weight    : 700;
    line-height    : 100%;
    text-align     : center;
    letter-spacing : -0.02em;
}

.synthesis-section-subtitle {
    font-size   : 16px;
    font-weight : 500;
    line-height : 130%;
    text-align  : center;
}

.synthesis-section-subtitle-md {
    font-size   : 20px;
    font-weight : 500;
    line-height : 130%;
    text-align  : center;
}

/* Forms */
.field-label {
    font-family   : Aeonik, Roboto, sans-serif;
    font-size     : 16px;
    font-weight   : 500;
    display       : block;
    margin-bottom : 6px;
    color         : var(--v-synthesis-brand-dark-blue-base);
}

.button-text-underline {
    text-decoration       : underline;
    text-underline-offset : 3px;
}
